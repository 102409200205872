import React from 'react';
import Layout from '../components/public/layout';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery, Link } from 'gatsby';
import default_photo from '../images/default_photo.png'

const OgloszeniaIIntencje = () => {
    const data = useStaticQuery(graphql`
	query {
		allContentfulOgloszeniaIIntencje(
			sort: {
				fields: publishDate,
				order: DESC
			}
		) {
			edges {
				node {
					title
					author
					publishDate(formatString: "D MMMM YYYY", locale: "pl")
					content{
						raw
					}
					linkName
				}
			}
		}
	}
	`)
    return (
        <Layout>
            <Helmet title={`Ogłoszenia parafialne | Parafia w Starych Skoszewach`}/>
            <div className="container">
                <div className="left-col" style={{width: '100%'}}>
                    <div className="list-of-articles">
                        <h2 style={{lineHeight: '80px'}}>Ogłoszenia parafialne</h2>
						{
							data.allContentfulOgloszeniaIIntencje.edges.map(edge => {
								const jsonContent = JSON.parse(edge.node.content.raw)
								return (
									<Link to={edge.node.linkName}>
										<div className="single">
											<img src={default_photo} alt="ogloszenie" />
											<div className='main-content'>
												<span className='title'>{edge.node.title}</span><br />
												<span className='date' style={{ textTransform: 'initial' }}>{edge.node.publishDate} | {edge.node.author}</span><br />
												<p>{jsonContent.content[0].content[0].value.substring(0, 200)} ...</p>
												<span style={{ textDecoration: 'underline', fontSize: 16, color: '#555' }}>CZYAJ DALEJ</span>
											</div>
										</div>
									</Link>
								)
							})
						}
                    </div>
                </div>
                {/* <div className="right-col">
                    <div className="facebook-panel"></div>
                </div> */}
            </div>
        </Layout>
    );
}

export default OgloszeniaIIntencje;
